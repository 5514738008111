<template>
	<div class="leadProgram">
		<div class="leadProgram__col1">
			<h1 class="mb-2">Become a Shift Lead</h1>
			<h4 style="opacity: 1; line-height: 1.2;" class="mb-3">Join our leadership team and jumpstart your career!</h4>
			<p>Core Benefits:</p>
			<ul class="mb-3">
				<li>Higher hourly pay</li>
				<li>Work alongside Jump management</li>
				<li>Gain experience leadning teams</li>
				<li>First step towards a full-time position</li>
			</ul>
			<p>Job Requirements:</p>
			<ul>
				<li>Comfortable with technology</li>
				<li>Professional presentation (you will be working directly with clients)</li>
				<li>Great communication</li>
				<li>Can follow directions</li>
				<li>Works great with teams</li>
				<li>Experience managing teams or leading a group of people preferred</li>
				<li>Must be available to work frequently in your area</li>
				<li>Clear background check</li>
			</ul>
		</div>
		<div class="leadProgram__col2">

			<div v-if="!userProfile && (userProfile && !userProfile.id)">
				<h4 class="mb-2">Please Log In to Apply</h4>
				<router-link :to="{name: 'loginNew'}">
				<button class="btn btn__primary">Login</button>
				</router-link>
			</div>

			<div v-if="(userProfile && userProfile.id) && (userProfile.leadApplicationSubmitted || !showForm)">
      	<h4 class="mb-2">Thank you for your application.</h4>
      	<div class="mb-5">Please make sure your profile is complete and upload your <a href="/account/resume">resumé</a> for review.</div>
      	<div class="strong" v-if="userProfile.leadStatus">Application Status: {{userProfile.leadStatus}}</div>
      </div>

      <div v-if="(userProfile && userProfile.id) && (!userProfile.leadApplicationSubmitted && showForm)">
				<h3 class="mb-3">Complete the Application Below:</h3>
				<form @submit.prevent>
	        <div class="mb-3">
	          <label for="firstname">First name</label>
	          <input v-model.trim="userProfile.firstName" autocomplete="given-name" type="text" placeholder="" id="firstname" readonly />
	        </div>
	        <div class="mb-3">
	          <label for="lastname">Last name</label>
	          <input v-model.trim="userProfile.lastName" autocomplete="family-name" type="text" placeholder="" id="lastname" readonly />
	        </div>
	        <div class="mb-3">
	          <label for="email">Email</label>
	          <input v-model.trim="userProfile.email" autocomplete="given-name" type="text" placeholder="" id="email" readonly />
	        </div>
	        <div class="mb-3">
	          <label for="phone">Phone</label>
	          <input v-model.trim="userProfile.phone" autocomplete="family-name" type="text" placeholder="" id="phone" readonly />
	        </div>
	        <div class="mb-3">
	          <label for="education">What is the highest level of education you've received?</label>
	          <v-select
	          	label="title"
	          	:options="school"
	          	:clearable="false"
	          	v-model="form.education"
	          	>
	          </v-select>
	        </div>
	        <div class="mb-3">
	          <label for="how">How many hours are you looking to work each week?</label>
	          <v-select
	          	label="title"
	          	:options="hoursOptions"
	          	:clearable="false"
	          	v-model="form.hours"
	          	>
	          </v-select>
	        </div>
	        <div class="mb-3">
	          <label for="nights">Are you generally available to work some nights and weekends?</label>
	          <v-select
	          	label="title"
	          	:options="yesNo"
	          	:clearable="false"
	          	v-model="form.nightsWeekends"
	          	>
	          </v-select>
	        </div>
	        <div class="mb-3">
	          <label for="distance">Are comfortable standing and walking for long periods of time?</label>
	          <v-select
	          	label="title"
	          	:options="yesNo"
	          	:clearable="false"
	          	v-model="form.walk"
	          	>
	          </v-select>
	        </div>
	        <div class="mb-3">
	          <label for="tech">Are you comfortable using apps and websites for work?</label>
	          <v-select
	          	label="title"
	          	:options="yesNo"
	          	:clearable="false"
	          	v-model="form.tech"
	          	>
	          </v-select>
	        </div>
	        <div class="mb-3">
	          <label for="transportation">Do you have reliable transportation back and forth to events?</label>
	          <v-select
	          	label="title"
	          	:options="yesNo"
	          	:clearable="false"
	          	v-model="form.transportation"
	          	>
	          </v-select>
	        </div>
	        <div class="mb-3">
	          <label for="background">Are you comfortable that you will pass a background check?</label>
	          <v-select
	          	label="title"
	          	:options="yesNo"
	          	:clearable="false"
	          	v-model="form.background"
	          	>
	          </v-select>
	        </div>
	        <div class="mb-3">
	          <label for="english">Are you fluent in english and can comfortably speak english with others?</label>
	          <v-select
	          	label="title"
	          	:options="yesNo"
	          	:clearable="false"
	          	v-model="form.english"
	          	>
	          </v-select>
	        </div>
	        
	        <div class="mb-3">
	          <label for="langauge">Do you speak any other languages? If so, please enter them below.</label>
	          <input v-model.trim="form.langauges" type="text" placeholder="" id="langauge"  />
	        </div>
	        <div class="mb-3">
	          <label for="areas">What nearby cities are you willing to drive to for work?</label>
	          <input v-model.trim="form.areas" type="text" placeholder="" id="areas"  />
	        </div>
	        <div class="mb-3">
	          <label for="leadingExperience">Tell us about your experience leading teams or groups of people.</label>
	          <textarea v-model.trim="form.leadingExperience" cols="30" rows="6" id="leadingExperience"></textarea>
	        </div>
	        <div class="mb-3">
	          <label for="clients">Tell us about times you've worked closely with clients or other important people.</label>
	          <textarea v-model.trim="form.clients" cols="30" rows="6" id="clients"></textarea>
	        </div>
	        <div class="mb-3">
	          <label for="anythingElse">Please tell us anything else we should know about you.</label>
	          <textarea v-model.trim="form.anythingElse" cols="30" rows="6" id="clients"></textarea>
	        </div>
	        <button :disabled="!valid" class="btn btn__primary" @click="submitForm()">
            Submit
            <transition name="fade">
              <span class="ml-2" v-if="performingRequest">
              <i class="fa fa-spinner fa-spin"></i>
              </span>
            </transition>
          </button>
	      </form>
	    </div>
      
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'

export default {
  name: 'leadProgram',
  data: () => ({
    performingRequest: false,
    hoursOptions: ['0-10', '10-20', '20-30', '30-40'],
    yesNo: ['yes', 'no', 'maybe'],
    school: ['high school', 'some college', 'college degree', 'graduate degree'],
    form: {},
    showForm: true
    // hours: '',
    // nightsWeekends: '',
   	// walk: '',
   	// tech: '',
   	// background: '',
   	// english: '',
   	// langauges: ''
  }),
  components: {
    Loader,
  },
  computed: {
    ...mapState(['currentUser', 'userProfile']),
    valid() {
    	if (this.form.education && this.form.hours && this.form.nightsWeekends && this.form.walk && this.form.tech && this.form.transportation && this.form.background && this.form.english) {
    		return true
    	} else {
    		return false
    	}
    }
  },
  methods: {
    submitForm() {
      this.performingRequest = true
      let form = this.form
      console.log(form)
      let store = this.$store
      store.dispatch('newLeadApplication', {
        form: form,
        user: this.userProfile
      })
      setTimeout(() => {
        this.performingRequest = false
        this.showForm = false
        form = {}
      }, 2000)
    }
  }
}

</script>